import { Container, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";

/**
 * @param {string} color - The color to be converted to a CSS variable
 * @param {number} saturation - The saturation of the color to be converted to a CSS variable
 * @returns {string} - The CSS variable
 * @example
 * colorToVar('brand.green', 600)
 * returns 'var(--chakra-colors-brand-green-600)'
*/
export const colorToVar = (color: string, saturation: number): string => {
  return `var(--chakra-colors-${color.replaceAll('.', '-')}-${saturation})`;
}

/**
 * @param {{filename: string, focus?:string}} image - Image object from Storyblok
 * @param {number} width - The desired width of the image
 * @returns {{string: photoUrl, string: blurUrl, dimensions: {width: number, height: number, blurWidth: number, blurHeight: number}}} - The photoUrl, blurUrl, and dimensions of the image
*/
export const getScaledPhotoSrc = (image: { filename: string, focus: string }, width: number = 1920): {
  photoUrl: string,
  blurUrl: string,
  dimensions: {
    width: number,
    height: number,
    blurWidth: number,
    blurHeight: number
  }
} => {
  if (image.filename === null) return { photoUrl: '', blurUrl: '', dimensions: { width: 0, height: 0, blurWidth: 0, blurHeight: 0 } };

  const originalWidth = Number(image?.filename.split('/')[5].split('x')[0]);
  const originalHeight = Number(image?.filename.split('/')[5].split('x')[1]);

  const dimensions = {
    height: Math.round(originalHeight * (width / originalWidth)),
    width: width,
    blurHeight: Math.round(originalHeight * (20 / originalWidth)),
    blurWidth: 20,
  }

  const photoUrl = image.focus && image.focus !== '' ?
    `${image.filename}/m/${dimensions.width}x${dimensions.height}/filters:focal(${image.focus})` :
    `${image.filename}/m/${dimensions.width}x${dimensions.height}/smart`;

  const blurUrl = image.focus && image.focus !== '' ?
    `${image.filename}/m/${dimensions.blurWidth}x${dimensions.blurHeight}/filters:focal(${image.focus})` :
    `${image.filename}/m/${dimensions.blurWidth}x${dimensions.blurHeight}/smart`;

  return {
    photoUrl,
    blurUrl,
    dimensions
  }
}

/**
 * @param {{filename: string, focus?:string}} image - Image object from Storyblok
 * @param {number} width - The desired width of the image
 * @param {number} height - The desired height of the image
 * @returns {{string: photoUrl, string: blurUrl, dimensions: {width: number, height: number, blurWidth: number, blurHeight: number}}} - The photoUrl, blurUrl, and dimensions of the image
*/
export const getCroppedPhotoSrc = (image: { filename: string, focus: string }, width: number = 0, height: number = 0): {
  photoUrl: string,
  blurUrl: string,
  dimensions: {
    width: number,
    height: number,
    blurWidth: number,
    blurHeight: number
  }
} => {
  if (image.filename === null) return { photoUrl: '', blurUrl: '', dimensions: { width: 0, height: 0, blurWidth: 0, blurHeight: 0 } };

  const maxDimension = Math.max(width, height);

  const dimensions = {
    height: height,
    width: width,
    blurHeight: height * (20 / maxDimension),
    blurWidth: width * (20 / maxDimension),
  }

  const photoUrl = image.focus && image.focus !== '' ?
    `${image.filename}/m/${dimensions.width}x${dimensions.height}/filters:focal(${image.focus})` :
    `${image.filename}/m/${dimensions.width}x${dimensions.height}/smart`;

  const blurUrl = image.focus && image.focus !== '' ?
    `${image.filename}/m/${dimensions.blurWidth}x${dimensions.blurHeight}/filters:focal(${image.focus})` :
    `${image.filename}/m/${dimensions.blurWidth}x${dimensions.blurHeight}/smart`;

  return {
    photoUrl,
    blurUrl,
    dimensions
  }
}

export const getRealLink = (link: { url: string, cached_url: string }, openInNewWindow: boolean): [linkHref: string, target: string] => {
  let linkHref, target;

  if (openInNewWindow) {
    target = "_blank";
  } else {
    target = "_self";
  }

  if (link.url !== '') {
    linkHref = link.url;
  } else {
    linkHref = link.cached_url?.replace(process.env.NEXT_PUBLIC_STORYBLOK_PAGES_URL, '').replace('//', '/');
    linkHref = linkHref?.indexOf('/') === 0 ? linkHref : `/${linkHref}`;
  }

  return [linkHref, target];
}

export const getTripTimeframes = (minDate?: DateTime, maxDate?: DateTime): SelectOption[] => {
  const nowMonth = DateTime.now().month;
  const nowYear = DateTime.now().year;

  const timeframes: SelectOption[] = [];

  if (nowMonth <= 2) {
    timeframes.push({ value: `Jan-Feb ${nowYear}`, label: `Jan-Feb ${nowYear}` });
  }

  if (nowMonth <= 4) {
    timeframes.push({ value: `Mar-Apr ${nowYear}`, label: `Mar-Apr ${nowYear}` });
  }

  if (nowMonth <= 8) {
    timeframes.push({ value: `May-Aug ${nowYear}`, label: `May-Aug ${nowYear}` });
  }

  timeframes.push({ value: `Sept-Dec ${nowYear}`, label: `Sept-Dec ${nowYear}` });

  for (let i = 1; i <= 2; i++) {
    timeframes.push({ value: `Jan-Feb ${nowYear + i}`, label: `Jan-Feb ${nowYear + i}` });
    timeframes.push({ value: `Mar-Apr ${nowYear + i}`, label: `Mar-Apr ${nowYear + i}` });
    timeframes.push({ value: `May-Aug ${nowYear + i}`, label: `May-Aug ${nowYear + i}` });
    timeframes.push({ value: `Sept-Dec ${nowYear + i}`, label: `Sept-Dec ${nowYear + i}` });
  }


  return timeframes.filter(timeframe => {
    const { startDate, endDate } = getStartEndDatesFromTimeframe(timeframe.value);

    if (minDate && endDate.diff(minDate).as('days') < 0) {
      return false;
    }

    if (maxDate && maxDate.diff(startDate).as('days') < 0) {
      return false;
    }

    return true;
  });
}

export const getStartEndDatesFromTimeframe = (dateString: string) => {
  const year = dateString.split(' ')[1];
  const months = dateString.split(' ')[0];
  const startMonth = months.split('-')[0].substring(0, 3);
  const endMonth = months.split('-')[1].substring(0, 3);

  const startDate = DateTime.fromFormat(`${startMonth} ${year}`, 'MMM yyyy');
  const endDate = DateTime.fromFormat(`${endMonth} ${year}`, 'MMM yyyy');

  return { startDate, endDate };
}

export const tripDateTimeframeComparison = (tripdate: string, timeframe: { startDate: DateTime, endDate: DateTime }) => {
  const thisTripDate = DateTime.fromFormat(tripdate, 'MM/dd/yyyy');

  return thisTripDate.diff(timeframe.startDate).as('days') >= 0 && thisTripDate.diff(timeframe.endDate).as('days') <= 0;
}

export const getImageSrcFromRootsId = (rootsId: string, locationArray: StoryblokLocation[]) => {
  const findImage = locationArray.filter(location => location.rootsId === rootsId)[0];

  return findImage?.image.filename;
}

export const ContentErrorFallback = ({ error, resetErrorBoundary }): JSX.Element => {
  TrackJS.track(error);

  return (
    // this returns a client side error message
    <Container
      maxW="container.lg"
      mt={{ base: '0px', lg: '150px' }}
      minH={'50vh'}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text as="h1" mb="10">Something went wrong...</Text>
      <Text>Sorry, there was an error loading this page. Please refresh and try again.</Text>
    </Container>
  );
}

export const getPossessive = (string: string) => {
  if (string.charAt(string.length - 1) === 's') {
    return `${string}'`;
  }

  return `${string}'s`;
}

export const getCookie = (key) => {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export const setCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}`
}

export const htmlToString = (html: string) => {
  if (html) {
    return html.replace(/<\//g, '&nbsp;</').replace(/<\s*br[^>]?>/, '\n').replace(/(<([^>]+)>)/g, "");
  }
  else {
    return "";
  }
}

export const triggerEvent = (element, eventName) => {
  if (element && eventName) {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  }
}

/**
 * Converts the color/saturation pair to the appropriate value for use with Chakra theming
 * @param color 
 * @param saturation 
 * @returns 
 */
export const convertColor = (color: string, saturation: string) => {
  //The white and black variables in the theme don't have saturation values
  return (color === "white" || color === "black") ? color : `${color}.${saturation}`
}

export const  shuffleArray = (array) => {
  const newArray = [...array]
  for (let i = newArray.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray
}
