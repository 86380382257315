import { extendTheme } from '@chakra-ui/react'

const colors = { 
  white: "#FFF",
  black: "#000",
  brand: {
    green: {
      50: '#ddf0ec', // ppm green 20%
      100: '#cdeae3', // ppm green 30%ish
      200: '#bee4d9', // ppm green 40%
      300: '#a0d6c9', // ppm green 60%
      400: '#8fd0c1', // ppm green 70%ish
      500: '#7dcaba', // ppm green 80%
      600: '#55c1ac', // ppm green 100%
      700: '#116a5f',
      800: '#014039',
      900: '#001714',
    },
    orange: {
      50: '#FFE7CD', // ppm orange 20%
      100: '#fedcb7', // ppm orange 30%ish
      200: '#FDD1A2', // ppm orange 40%
      300: '#FBBB7B', // ppm orange 60%
      400: '#fab168', // ppm orange 70%ish
      500: '#F9A954', // ppm orange 80%
      600: '#F7941D', // ppm orange 100%
      700: '#7c4501',
      800: '#4c2900',
      900: '#1e0c00',
    },
    darkgray: {
      50: '#DADBDD', // ppm dark gray 20%
      100: '#c9cacd', // ppm dark gray 30%ish
      200: '#B8B9BD', // ppm dark gray 40%
      300: '#98999D', // ppm dark gray 60%
      400: '#88898d', // ppm dark gray 70%ish
      500: '#797A7E', // ppm dark gray 80%
      600: '#58595b', // ppm dark gray 100%
      700: '#3d3f43',
      800: '#22262b',
      900: '#001010',
    },
    lightgray: {
      50: '#E7E8EA', // ppm light gray 20%
      100: '#dcdddf', // ppm light gray 30%ish
      200: '#D1D2D4', // ppm light gray 40%
      300: '#BCBDC1', // ppm light gray 60%
      400: '#b2b3b7', // ppm light gray 70%ish
      500: '#A8A9AD', // ppm light gray 80%
      600: '#939598', // ppm light gray 100%
      700: '#939598',
      800: '#939598',
      900: '#939598',
    }
  },
}

const shadows = {
  outline: '0 0 0 2px #55c1ac',
}

const fonts = {
  heading: `'Lora', sans-serif`,
  subheading: `'Montserrat', sans-serif`,
  body: `'Montserrat', sans-serif`
}

const textStyles = {
  subNavTitle: {
    fontSize: '16px',
    textTransform: 'uppercase',
    fontWeight: '600',
    lineHeight: '110%',
    letterSpacing: '-1%',
    textColor: 'brand.green.600'
  },
  subNavDescription: {
    fontSize: '14px',
    fontWeight: '400',
  },
  tripJournalNavLink: {
    fontSize: '14px',
    textColor: 'brand.green.600',
    textTransform: 'uppercase',
    fontWeight: '600',
  },
  tripJournalNavInfo: {
    fontSize: '12px',
    lineHeight: '110%'
  },
}

const layerStyles = {
  darkForm: {
    color: "whiteAlpha.800",
    backgroundColor: "brand.darkgray.600",
    _focus: {
      borderColor: 'brand.orange.600',
      boxShadow: '0 0 0 1px var(--chakra-colors-brand-orange-600)!important',
    },
    _placeholder: { color: 'whiteAlpha.600' },
  },
  lightForm: {
    color: "blackAlpha.700",
    backgroundColor: "white",
    _focus: {
      borderColor: 'brand.orange.600',
      boxShadow: '0 0 0 1px var(--chakra-colors-brand-orange-600)!important',
    },
    _placeholder: { color: 'blackAlpha.600' },
  },
  darkTableHeading: {
    color: "whiteAlpha.800",
    backgroundColor: "brand.green.600",
  },
  darkTableBody: {
    color: "whiteAlpha.800",
    backgroundColor: "brand.darkgray.700",
  },
}

const styles = {
  global: {
    h1: { 
      fontFamily: `'Lora', sans-serif`,
      fontSize: {base: '3xl', md: '5xl'},
      color: 'brand.darkgray.600',
      fontWeight: '900',
      lineHeight: '110%',
    },
    h2: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: { base: 'xl', md: '4xl' },
      color: 'brand.darkgray.600',
      lineHeight: '110%',
      fontWeight: '700',
    },
    h3: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: { base: 'xl', md: '3xl' },
      color: 'brand.darkgray.600',
    },
    h4: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: { base: 'xl', md: 'xl' },
      color: 'brand.darkgray.600',
    },
    h5: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: { base: 'md', md: 'lg' },
      color: 'brand.darkgray.600',
    },
    h6: {
      fontFamily: `'Montserrat', sans-serif`,
      fontSize: 'md',
      color: 'brand.darkgray.600',
    },
    p: {
      fontFamily: `'Montserrat', sans-serif`,
      color: 'brand.darkgray.800',
      marginBottom: '.5em',
      letterSpacing: 'tight',
    },
    a: {
      color: 'brand.green.600',
      _hover: {
        textDecoration: 'underline',
      },
    },
    b: {
      fontWeight: '700'
    },
    strong: {
      fontWeight: '700'
    },
    blockquote: {
      borderLeft: `1px solid`,
      paddingLeft: '1rem',
      marginLeft: '.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      borderColor: 'brand.orange.600',
      fontStyle: 'italic',
    },
    ul: {
      marginLeft: '1.5rem',
    },
    ol: {
      marginLeft: '1.5rem',
    },
    li: {
      p: {
        marginBottom: 0,
        letterSpacing: 'tighter'  
      },
      _last: {marginBottom: '.5rem'}
    },
    hr: {
      borderColor: 'brand.orange.600',
    },
    input: {
      borderColor: 'brand.orange.600',
    },
  }
}

export default extendTheme({ colors, shadows, fonts, textStyles, styles, layerStyles });


